




































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { CONST } from '@/store/auth/constants';
import { User } from '@/store/auth/types';

@Component({})
export default class LoginView extends Vue {
  form = {
    login: '',
    password: '',
  };

  rules = {
    login: [{ required: true, message: 'Введите логин.', trigger: 'change' }],
    password: [{ required: true, message: 'Введите пароль.', trigger: 'change' }],
  };

  formValidity: Record<string, boolean> = {
    login: false,
    password: false,
  };

  @Getter(CONST.GET_USER, { namespace: CONST.AUTH }) user?: User | null;

  @Action(CONST.LOGIN, { namespace: CONST.AUTH }) loginAction: any;

  @Watch('user', { immediate: true }) onChangeUser(cur?: User | null) {
    if (cur) {
      this.$router.push('/');
    }
  }

  get isValid() {
    return !Object.entries(this.formValidity).some(([, value]) => !value);
  }

  onValidate(field: string, validity: boolean) {
    this.formValidity[field] = validity;
  }

  async login() {
    if (this.isValid) {
      const res = await this.loginAction(this.form);
      if (!res.ok) {
        this.$notify.error({
          title: 'Ошибка!',
          message: '',
        });
      }
    }
  }

  onKeyUp(e: any) {
    if (e.keyCode === 13) {
      this.login();
    }
  }

  mounted() {
    window.addEventListener('keyup', this.onKeyUp);
  }

  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyUp);
  }
}
